(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mwc-markets-core"), require("vue"), require("vue-custom-element"));
	else if(typeof define === 'function' && define.amd)
		define(["mwc-markets-core", "vue", "vue-custom-element"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsImport"] = factory(require("mwc-markets-core"), require("vue"), require("vue-custom-element"));
	else
		root["mwcMarketsImport"] = factory(root["mwcMarketsCore"], root["Vue"], root["VueCustomElement"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__49__, __WEBPACK_EXTERNAL_MODULE__78__) {
return 