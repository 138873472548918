<template>
    <IMPORTUI
        ref="UI"
        :skin="skin"
        :showLoading="showLoading"
        :isClickDone="isClickDone"
        :dataModel="dataModel"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        :showImportTable="showImportTable"
        @import-complete="importComplete"
        @upload-click="uploadClick"
    >
    </IMPORTUI>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import IMPORTUI from './import-ui';
const { mixins } = mwcMarketsCore;

export default {
    name: 'mwc-markets-import',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    data() {
        return {
            defaultConfig: {
                settings: {
                    layout: '',
                    skin: '',
                    showHeader: false,
                    showSetting: false,
                    showBorder: false,
                    showRowNumber: false,
                    removable: true,
                    frozenColumn: 1,
                    autoHeight: true,
                    stickyLeadBreakPoints: [
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 2,
                            minWidth: 0,
                            maxWidth: 500
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 4,
                            minWidth: 500,
                            maxWidth: 640
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 6,
                            minWidth: 640,
                            maxWidth: 960
                        }
                    ],
                    fileLoadUrl:
                        'https://rtqimg.morningstar.com/template/InvestmentListSample.xlsx'
                },
                labels: labels,
                intlNamespace: 'mwc-markets-import'
            },
            showImportTable: false,
            showLoading: false,
            isClickDone: false,
            dataModel: []
        };
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    methods: {
        exposeMethods() {
            // clear preview upload file.
            return ['refresh'];
        },
        uploadClick(formData) {
            this.isClickDone = true;
            this.showLoading = true;
            this.mktdata
                .securitiesByFormData(formData)
                .then(resData => {
                    this.isClickDone = false;
                    this.showLoading = false;
                    this.showImportTable = true;
                    const rows = [];
                    resData.forEach(security => {
                        rows.push({
                            status: security.isValid(),
                            name: security.name,
                            queryKey: security.queryKey,
                            mstarSymbol: security.mstarSymbol,
                            mstarType: security.mstarType,
                            currency: security.currency,
                            Exch: security.Exch,
                            performanceId: security.performanceId,
                            symbol: this.getSavedSymbol(security)
                        });
                    });
                    this.dataModel = rows;
                })
                .catch(() => {
                    this.showLoading = false;
                });
        },
        importComplete(param) {
            this.$emit('import-complete', param);
        },
        refresh() {
            this.dataModel = [];
            this.showImportTable = false;
            this.$refs.UI.refresh();
        }
    },
    components: {
        IMPORTUI
    }
};
</script>
