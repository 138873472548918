<template>
    <MODAL
        :width="1200"
        :visible="true"
        :class="namespaceClass('import-manager')"
        :title="initedLabels.import"
        :skin="skin"
    >
        <button
            slot="header-actions"
            class="mds-button mds-button--icon-only"
            @click="closeImportManagement"
        >
            <svg class="mds-icon mds-button__icon mds-button__icon--left">
                <use :xlink:href="mdsIcon('remove')">
                    <title>Close</title>
                </use>
            </svg>
        </button>
        <div
            :class="namespaceClass('import-container')"
            slot="content"
            ref="importContainer"
        ></div>
    </MODAL>
</template>
<script>
import labels from '../../assets/metadata/label.json';
import { mapGetters, mapState } from 'vuex';
import utils, { guid } from '../../util/';
import MODAL from '../ui/modal';
import 'mwc-markets-import';
export default {
    name: 'ImportManger',
    components: { MODAL },
    data() {
        return {};
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('settings', ['skin']),
        ...mapGetters('account', ['token']),
        ...mapState('application', ['globalConfigLoaded', 'paths']),
        initedLabels() {
            return labels.en;
        }
    },
    watch: {
        skin(value) {
            if (value && this.importComponent) {
                this.importComponent.skin = value;
            }
        }
    },
    mounted() {
        this.initImport();
    },
    methods: {
        closeImportManagement() {
            this.$emit('close');
        },
        initImport() {
            const config = utils.extend(
                true,
                {
                    skin: this.skin,
                    layout: 'mbg',
                    autoHeight: false,
                    overridePaths: this.paths
                },
                this.token
            );
            const customElement = document.createElement('mwc-markets-import');
            const mwcId = `import-${guid()}`;
            customElement.setAttribute('mwc-id', mwcId);
            customElement.config = {
                settings: config
            };
            this.$refs.importContainer.appendChild(customElement);
            this.importComponent = customElement;
            customElement.skin = this.skin;
            this.importComponent.addEventListener(
                'import-complete',
                this.importComplete
            );
        },
        importComplete(params) {
            this.$emit('import-complete', params.detail[0]);
        }
    }
};
</script>
