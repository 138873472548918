import Vue from 'vue';
import VueCustomElement from 'vue-custom-element';
import Component from './src/import.vue';
Vue.use(VueCustomElement);
const customElement = Vue.customElement(Component.name, Component, {
    vueInstanceCreatedCallback() {
        const vueInstance = this.getVueInstance();
        let exposeMethods;
        if (typeof vueInstance.exposeMethods === 'function') {
            exposeMethods = vueInstance.exposeMethods();
        } else {
            exposeMethods = vueInstance.exposeMethods;
        }
        if (Array.isArray(exposeMethods)) {
            exposeMethods.forEach(m => {
                if (typeof vueInstance[m] === 'function') {
                    this[m] = vueInstance[m];
                }
            });
        }
    }
});
export default customElement;
