export const DataPoints = [
    {
        id: 'status',
        dataType: 'icon',
        width: 45
    },
    {
        id: 'name',
        label: 'matchedName',
        width: 150
    },
    {
        id: 'queryKey',
        width: 140,
        dataType: 'search'
    },

    {
        id: 'mstarSymbol',
        label: 'matchedSymbol',
        width: 150
    },
    {
        id: 'mstarType',
        label: 'matchedMstarType',
        width: 150
    },
    {
        id: 'currency',
        label: 'matchedCurrency',
        width: 150
    },
    {
        id: 'Exch',
        label: 'matchedExch',
        width: 150
    },
    {
        id: 'performanceId',
        label: 'matchedPerformanceId',
        width: 150
    }
];
