<template>
    <div :class="cls">
        <div :class="[namespace('header')]">
            <div :class="[namespace('header-title')]">
                {{ initedLabels.import }}
                <markets-ui-button
                    icon="question-circle"
                    :skin="watchedSettings.skin"
                    variation="icon-only"
                    :text="initedLabels.help"
                    @click="clickHelpIcon"
                />
            </div>
            <div :class="namespace('header-tips')">
                {{ initedLabels.suportTypesLabel }}:
                {{ initedLabels.identifierTypes }}
            </div>
            <div
                :class="[namespace('header-content')]"
                :skin="watchedSettings.skin"
            >
                <markets-ui-form>
                    <markets-ui-fieldset
                        horizontal
                        :legend="initedLabels.fileLocationLabel"
                        :skin="watchedSettings.skin"
                    >
                        <div :class="namespace('input-section')">
                            <input
                                type="file"
                                ref="myFiles"
                                :class="namespace('input-upload')"
                                @change="changeFiles"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                            />
                            <markets-ui-input
                                :class="namespace('input-text')"
                                :skin="watchedSettings.skin"
                                size="small"
                                type="text"
                                ref="input"
                                readonly
                                :value="fileValue.name"
                                hiddenLabel
                                label=""
                                @keyup.enter="textInput"
                            />
                        </div>
                        <markets-ui-button
                            variation="primary"
                            @click="upLoadFile"
                            :text="initedLabels.upload"
                        />
                    </markets-ui-fieldset>
                </markets-ui-form>
            </div>
        </div>
        <div :class="[namespace('content')]">
            <div :class="[namespace()]" v-if="isExcelFile">
                <div :class="[namespace('content-title')]">
                    {{ initedLabels.verificationLabel }}
                </div>
                <div :class="[namespace('content-tips')]">
                    {{ initedLabels.notExcelMsg }}
                </div>
            </div>
            <div
                :class="[namespace('content-wrapper')]"
                v-if="showImportTable && !isExcelFile"
            >
                <div :class="[namespace('content-wrapper-header')]">
                    <div>{{ initedLabels.importInfo }}</div>
                    <markets-ui-button
                        :skin="skin"
                        :text="initedLabels.import"
                        variation="primary"
                        @click="importFile"
                    />
                </div>
                <markets-ui-grid
                    :skin="watchedSettings.skin"
                    :option="gridOptions"
                    :columns="gridColumns"
                    :rows="transformDataModel"
                    :labels="gridLabels"
                    @visible-rows-update="handleSubscribe"
                    @cell-mouse-enter="onMouseEnter"
                    @cell-mouse-leave="onMouseLeave"
                    @cell-click="onCellClick"
                >
                </markets-ui-grid>
            </div>
            <markets-ui-loading
                :skin="watchedSettings.skin"
                :visible="showLoading"
            ></markets-ui-loading>
        </div>
        <markets-ui-popover
            :skin="watchedSettings.skin"
            :visible="popoverVisible"
            :triggered-by="popoverTriggeredId"
            :title-hidden="false"
            :title="initedLabels.help"
            :width="popoverWidth"
            @hide="hidePopover"
            :class="namespace('popover')"
        >
            <div :class="namespace('help')">
                <section>
                    <div :class="namespace('help-header')">
                        {{ initedLabels.introTip }}
                    </div>
                    <div
                        :class="[
                            namespace('help-header-item'),
                            namespace('help-security-type')
                        ]"
                    >
                        {{ securityTypeLabel }}
                    </div>
                </section>
                <section>
                    <div :class="namespace('help-title')">
                        {{ initedLabels.mandatoryLabel }}
                    </div>
                    <div
                        :class="namespace('help-header-item')"
                        v-for="item in listColumn"
                        :key="item"
                    >
                        {{ item }}
                    </div>
                </section>
                <section>
                    <div :class="namespace('help-title')">
                        {{ initedLabels.optionnalLabel }}
                    </div>
                    <div
                        :class="namespace('help-header-item')"
                        v-for="item in codeColumn"
                        :key="item"
                    >
                        {{ item }}
                    </div>
                </section>
                <section>
                    <div :class="namespace('help-title')">
                        {{ initedLabels.exampleFileLabel }}
                    </div>

                    <a
                        :class="namespace('help-download')"
                        :href="watchedSettings.fileLoadUrl"
                        @click="downLoad($event)"
                        >{{ initedLabels.investSample }}</a
                    >
                </section>
            </div>
        </markets-ui-popover>
        <markets-ui-dialog
            :cancel="dialog.cancel"
            :resolve="dialog.resolve"
            :visible="dialog.visible"
            :message="dialog.message"
            :skin="skin"
            action-required
            @click="clickDialog"
        >
        </markets-ui-dialog>
        <mwc-markets-autocomplete
            mwc-id="importMarketsAutocomplete"
            :skin="skin"
            :width="width"
            :classes="namespace('autocomplete')"
        />
        <markets-ui-popover
            :visible="popoverTips.visible"
            :triggered-by="popoverTips.triggeredBy"
            :skin="skin"
            :custom-class="namespace('popover-tips')"
        >
            {{ initedLabels.tipsWarningMsg }}
        </markets-ui-popover>
    </div>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import { DataPoints } from './metadata/dataPoints';
const { utils, mixins } = mwcMarketsCore;
const ALERT_STATUS = 'alert';
export default {
    name: 'mwc-markets-import-ui',
    mixins: [mixins.componentUI],
    props: {
        classes: {
            type: Array,
            default() {
                return [];
            }
        },
        showImportTable: {
            type: Boolean,
            default() {
                return false;
            }
        },
        dataModel: {
            type: Array,
            default() {
                return [];
            }
        },
        isClickDone: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            width: 350,
            securityType: ['symbol', 'cusip', 'isin', 'mcode'],
            dialog: {
                visible: false,
                message: '',
                reject: {},
                actionInfo: {},
                cancel: {
                    label: 'Cancel'
                },
                resolve: {
                    label: 'OK'
                }
            },
            popoverWidth: 300,
            fileValue: '',
            popoverVisible: false,
            popoverTriggeredId: null,
            listColumn: ['IBM', 'MORN', 'T'],
            codeColumn: ['XNAS', 'XNYS', 'XTSE', 'MORN'],
            transformDataModel: [],
            popoverTips: {
                visible: false,
                triggeredId: ''
            },
            visibleIds: []
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.autocomplete = this.$el.querySelector(
                'mwc-markets-autocomplete'
            );
            this.$refs.input.$el.focus();
        });
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('import');
        this.autocompleteItem = null;
        this.autocompleteId = null;
        this.preUploadFile = null;
        this.clickUploadBtn = false;
        this.clickImportBtn = false;
        this.clickAutocomplete = false;
    },
    watch: {
        dataModel: {
            handler(value) {
                this.getDataModel(value);
            },
            deep: true
        },
        visibleIds: {
            handler(value, oldValue) {
                // find the removed item and un-register autocomplete
                const unRegisterIds = oldValue.filter(
                    item => utils.findIndex(value, d => d === item) === -1
                );
                if (unRegisterIds.length) {
                    unRegisterIds.forEach(elementId => {
                        this.unRegisterAutcomplete(elementId);
                    });
                }
                // find the new item and register autocomplete
                const registerIds = value.filter(
                    item => utils.findIndex(oldValue, d => d === item) === -1
                );
                if (registerIds.length) {
                    registerIds.forEach(elementId => {
                        this.registerAutcomplete(elementId);
                    });
                }
            },
            deep: true
        }
    },
    computed: {
        cls() {
            const cls = [this.namespace(), ...this.classes];
            if (this.skin) {
                cls.push(this.namespace(`-${utils.getSkinSuffix(this.skin)}`));
            }
            return cls;
        },
        isExcelFile() {
            if (this.fileValue) {
                const fileVal = this.fileValue.name.split('.');
                const suffix = fileVal[fileVal.length - 1];
                const stauts = suffix === 'xls' || suffix === 'xlsx';
                return !stauts;
            }
            return false;
        },
        securityTypeLabel() {
            const typeArry = this.securityTypeOption.map(item => item.name);
            return typeArry.join('|');
        },
        securityTypeOption() {
            return this.securityType.map(item => {
                return {
                    value: item,
                    name: this.initedLabels[item]
                };
            });
        },
        gridOptions() {
            const option = {};
            [
                'scrollbarSize',
                'removable',
                'rowHeight',
                'autoHeight',
                'frozenColumn',
                'showRowNumber',
                'layout',
                'rowsPerPage',
                'stickyLeadBreakPoints'
            ].forEach(key => {
                if (typeof this.watchedSettings[key] !== 'undefined') {
                    option[key] = this.watchedSettings[key];
                }
            });
            utils.extend(true, option, this.watchedSettings.table);
            return option;
        },
        gridColumns() {
            return DataPoints.map(f => {
                return utils.extend(true, f, {
                    name: this.initedLabels[f.label || f.id],
                    sortable: this.watchedSettings.sortable,
                    cellClass:
                        f.id === 'status' ? this.namespace('column-status') : ''
                });
            });
        },
        gridLabels() {
            const labels = {};
            [
                'previousPage',
                'previousColumn',
                'nextPage',
                'nextColumn'
            ].forEach(key => {
                labels[key] = this.initedLabels[key];
            });
            // Use the warning tip as the aria-label for alert icon
            labels.alert = this.initedLabels.statusWarningTip;
            return labels;
        }
    },
    methods: {
        acMwcId() {
            return `Import-${utils.guid()}`;
        },
        changeFiles() {
            if (this.$refs['input'] && this.$refs['input'].$el) {
                this.$refs['input'].$el.focus();
            }
            const inputEl = this.$refs.myFiles;

            if (inputEl && inputEl.files && inputEl.files.length) {
                this.fileValue = inputEl.files[0];
            }
        },
        refresh() {
            this.fileValue = '';
            this.preUploadFile = null;
            if (this.$refs.myFiles && this.$refs.myFiles.files[0]) {
                this.$refs.myFiles.value = '';
            }
        },
        getDataModel(value) {
            this.transformDataModel = [];
            this.transformDataModel = utils.extend(
                true,
                [],
                this.transformDataModel,
                value
            );
            this.transformDataModel.forEach(item => {
                item.status = item.status ? '' : ALERT_STATUS;
                if (!item.inputId) {
                    item.inputId = item.id = this.acMwcId();
                }
            });
        },
        clickHelpIcon(e) {
            this.popoverTriggeredId = utils.computeTriggerId(e.target);
            this.popoverVisible = !this.popoverVisible;
        },
        hidePopover() {
            this.popoverVisible = false;
        },
        downLoad(e) {
            const param = {
                url: this.watchedSettings.fileLoadUrl,
                name: this.initedLabels.investSample,
                method: 'get'
            };
            utils.exportForIframe(param);
            e.preventDefault();
            return false;
        },
        upLoadFile(e) {
            e.preventDefault();
            if (this.preUploadFile && this.preUploadFile !== this.fileValue) {
                this.dialog.visible = true;
                this.dialog.message = this.initedLabels.uploadNewFile;
                this.clickUploadBtn = true;
            } else {
                this.uploadClick();
            }
        },
        selectAutocomplete(item, elementId) {
            const target = utils.find(
                this.transformDataModel,
                item => item.inputId === elementId
            );
            this.dialog.visible = true;
            this.autocompleteId = elementId;
            this.autocompleteItem = this.getAutocompleteTransform(
                item,
                elementId
            );
            const msg = utils.stringFormat(this.initedLabels.changeSymbolMsg, {
                symbol: target.queryKey,
                ticker: item.symbol
            });
            this.clickAutocomplete = true;
            this.dialog.message = msg;
        },
        clickDialog(type) {
            const idx = utils.findIndex(
                this.transformDataModel,
                item => item.inputId === this.autocompleteId
            );
            const autocompleteId = this.autocompleteId;
            if (type === 'resolve') {
                if (this.clickUploadBtn) {
                    this.clickUploadBtn = false;
                    this.uploadClick();
                } else if (this.clickAutocomplete) {
                    this.clickAutocomplete = false;
                    this.unRegisterAutcomplete(autocompleteId);
                    this.autocompleteItem.status = this.autocompleteItem.status
                        ? ''
                        : ALERT_STATUS;
                    this.$set(
                        this.transformDataModel,
                        idx,
                        this.autocompleteItem
                    );
                    this.$nextTick(() => {
                        this.registerAutcomplete(autocompleteId);
                    });
                } else if (this.clickImportBtn) {
                    this.clickImportBtn = false;
                    this.importFileAction();
                }
                this.dialog.visible = false;
            } else if (type === 'cancel') {
                this.$set(
                    this.transformDataModel,
                    idx,
                    this.transformDataModel[idx]
                );
                this.dialog.visible = false;
            }
            this.autocompleteItem = null;
            this.autocompleteId = null;
        },
        uploadClick() {
            if (this.isClickDone || !this.fileValue || this.isExcelFile) {
                return;
            }
            const param = {
                file: this.fileValue
            };
            this.preUploadFile = this.fileValue;
            const formData = new FormData();
            for (const key in param) {
                formData.append(key, param[key]);
            }
            this.$emit('upload-click', formData);
        },
        importFile() {
            const errTarget = this.transformDataModel.filter(
                item => item.status === ALERT_STATUS
            );
            if (errTarget.length) {
                this.dialog.visible = true;
                this.clickImportBtn = true;
                this.dialog.message = this.initedLabels.importAction;
            } else {
                this.importFileAction();
            }
        },
        importFileAction() {
            const importSecurities = [];
            this.transformDataModel.forEach(item => {
                if (item.status !== ALERT_STATUS && item.mstarSymbol) {
                    importSecurities.push(item.symbol);
                }
            });
            if (importSecurities.length) {
                const duplicateArr = Array.from(new Set([...importSecurities]));
                this.$emit('import-complete', duplicateArr);
            }
        },
        getAutocompleteTransform(item, elementId) {
            return {
                currency: item.currency,
                mstarSymbol: item.symbol,
                mstarType: item.securityType,
                performanceId: item.performanceId,
                Exch: item.exchange,
                queryKey: item.symbol,
                ticker: item.identifier,
                name: item.name,
                inputId: elementId,
                id: elementId,
                customClass: '',
                status: true,
                symbol: item.identifier
            };
        },
        handleSubscribe(obj) {
            this.visibleIds = obj.visibleRows.map(rowItem => rowItem.inputId);
        },
        registerAutcomplete(elementId) {
            this.$nextTick(() => {
                this.autocomplete.register(elementId, response => {
                    this.selectAutocomplete(response, elementId);
                });
            });
        },
        unRegisterAutcomplete(elementId) {
            this.autocomplete.unregister(elementId);
        },
        onMouseEnter(d) {
            const { e, column, row } = d;
            if (column.id === 'status' && row.status) {
                this.popoverTips.triggeredBy = utils.computeTriggerId(
                    e.target.querySelector('svg')
                );
                this.popoverTips.visible = true;
            }
        },
        onMouseLeave() {
            this.popoverTips.visible = false;
        },
        onCellClick(event) {
            const column = event.column;
            const row = event.row;
            if (column.id === 'remove') {
                const removeId = utils.findIndex(
                    this.transformDataModel,
                    d => d.id === row.id
                );
                this.transformDataModel.splice(removeId, 1);
            }
        },
        textInput() {
            this.$refs.myFiles.click();
        }
    }
};
</script>
<style lang="scss">
@import '@core/src/mds/constants.scss';
$namespace: 'mwc-markets-import';
.#{$namespace} {
    height: 100%;
    @include mds-body-text-m();
    width: 100%;
    padding: 10px 0;
    .mds-input___markets[readonly] {
        box-shadow: $mds-form-box-shadow-default;
        background-color: $mds-color-neutral-90;
        &:focus {
            box-shadow: $mds-box-shadow-focus;
        }
    }
    &-header {
        &-tips {
            padding: 5px 0;
        }
    }
    .markets-ui-form {
        width: 270px;
    }
    &-input-section {
        position: relative;
        width: 200px;
    }
    &-input-file {
        position: absolute;
        top: 0px;
        z-index: 1;
        opacity: 0;
    }
    &-input-text {
        position: absolute;
        width: 200px;
        top: 0px;
        .mds-input___markets[readonly] {
            padding-left: $mds-space-three-quarter-x;
        }
    }
    &-content {
        height: calc(100% - 125px);
        &-wrapper {
            border-top: 1px solid $mds-text-color-primary;
            margin-top: 10px;
            padding: 5px 0;
            height: 100%;
            &-header {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                & > button {
                    height: 26px;
                }
            }
        }
        &-tips {
            color: $mds-feedback-color-error;
        }
    }
    &-select-type {
        width: 200px;
    }

    &-upload-button {
        margin-left: 20px;
    }
    &-input-upload {
        position: absolute;
        top: 0px;
        z-index: 1;
        opacity: 0;
        width: 200px;
    }

    &-popover {
        z-index: 10;
        .mds-section___markets {
            max-height: 410px;
        }
    }
    &-help {
        @include mds-ui-text-s();
        section {
            padding: $mds-space-half-x 0;
            &:first-child {
                padding-top: 0;
            }
        }
        &-title {
            font-weight: $mds-typography-font-weight-bold;
        }
        a {
            color: $mds-text-color-interactive-primary;
        }
        &-security-type {
            padding-top: $mds-space-1-x;
        }
    }
    &-help-download {
        @include mds-link();
    }
    &-column-status {
        svg {
            stroke: $mds-feedback-color-error !important;
        }
    }
}

.#{$namespace} {
    &-popover-tips.markets-ui-popover {
        background: $mds-feedback-color-error;
        border-color: $mds-text-color-primary-on-dark;
        .mds-section__content___markets {
            color: $mds-text-color-primary-on-dark;
        }
    }
}
.#{$namespace}--dark-gray.#{$namespace} {
    background: $mds-background-color-dark-gray;
    color: $mds-text-color-primary-on-dark;
    border-bottom-color: $mds-color-neutral-20;

    .mds-input___markets[readonly] {
        background-color: $mds-color-neutral-20;
    }
    .#{$namespace} {
        color: $mds-text-color-primary-on-dark;
        &-header {
            color: $mds-text-color-primary-on-dark;
            &-circle {
                fill: $mds-text-color-primary-on-dark;
            }
        }
        &-content {
            &-wrapper {
                border-top-color: $mds-text-color-secondary-on-dark;
            }
        }
    }
}
</style>
