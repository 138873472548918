var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("IMPORTUI", {
    ref: "UI",
    attrs: {
      skin: _vm.skin,
      showLoading: _vm.showLoading,
      isClickDone: _vm.isClickDone,
      dataModel: _vm.dataModel,
      settings: _vm.settings,
      labels: _vm.initedLabels,
      formatter: _vm.formatter,
      showImportTable: _vm.showImportTable
    },
    on: {
      "import-complete": _vm.importComplete,
      "upload-click": _vm.uploadClick
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }